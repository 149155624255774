import moment from "moment-timezone";
import { CONSTANTS } from "consts";
import { Action, AdminAppContext } from "contexts";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { 
  useGetAppVersionsQuery,
  useAddOrUpdateAppVersionMutation,
  useDeleteAppVersionMutation,
 } from "services";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { errorHandle, setTimeWithTimeZone } from "utils";
import Swal from "sweetalert2";

const envMap: any = {
  	0: 'Production',
  	1: 'Alpha',
  	2: 'Beta',
  	3: 'Development',
}

const platformMap: any = {
  1: 'iOS',
  2: 'Android',
  3: 'Aviron Android',
  4: 'Browser'
}

const appMap: any = {
  1: 'Aviron Rower',
  2: 'C2 Rower',
  4: 'C2 Skier',
  8: 'C2 Bike',
  16: 'Aviron Rower Go',
  32: 'MyAviron',
  64: 'Water Rower',
  128: 'Aviron Bike',
  256: 'Aviron Bike Go',
  512: 'Aviron Treadmill',
  1024: 'Aviron Treadmill Go',
}

const useMainHook = () => {
  const { global, globalDispatch } = React.useContext(AdminAppContext);
  const [addOrUpdateAppVersion] = useAddOrUpdateAppVersionMutation();
  const [deleteAppVersion] = useDeleteAppVersionMutation();

  React.useEffect(() => {
    if (global.current_page !== CONSTANTS.SIDEBAR_PAGEID.APP_VESION) {
      globalDispatch(
        Action.global.setState({
          current_page: CONSTANTS.SIDEBAR_PAGEID.APP_VESION,
        })
      );
    }
  }, [global.current_page, globalDispatch]);

  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [dataShow, setDataShow] = React.useState({} as any);
  const [openAdd, setOpenAdd] = React.useState(false);

  const location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const env = searchParams.get("env");
  const platform = searchParams.get("platform") || "";
  const pageParam = searchParams.get("page") || 0;
  const appIdParam = searchParams.get("appId") || "";
  const version = searchParams.get("version") || "";
  const [queryParameter, setQueryParameter] = React.useState<any>({
    page: Number(pageParam) || 0,
    env: env !== null && env !== undefined && env !== "" ? env : -1,
    appId: appIdParam || 1,
    platform: platform,
    version: version,
  });
  let {
    data: appVersions,
    loading: loadingAppVersions,
    refetch: refetchAppVersions,
  } = useGetAppVersionsQuery({
    variables: {
      input: {
        appId: Number(queryParameter?.appId) || "1",
        environment: Number(queryParameter?.env) || -1,
        version: queryParameter?.version || ""
      },
    } as any,
    fetchPolicy: "no-cache",
  });

  const defaultNewVersion = {
    id: "00000000-0000-0000-0000-000000000000",
    name: 'Aviron Rower',
    description: 'Aviron Rower',
    version: "",
    forcedUpdate: false,
    platform: 1,
    deployAt: Date.now(),
    appID: +queryParameter?.appId,
    envID: +queryParameter?.env || 3,
    serverUrl: "",
  }
  const [addVersion, setAddVersion] = React.useState(defaultNewVersion as any);

  React.useEffect(() => {
    let urlParams = `?appId=${queryParameter.appId}`;
    if (queryParameter.env !== null && queryParameter.env !== undefined && queryParameter.env !== "" && +queryParameter.env >= 0) {
      urlParams = urlParams + `&env=${queryParameter.env}`;
    } else {
      urlParams = urlParams + `&env=-1`;
    }
    if (queryParameter.version) {
      urlParams = urlParams + `&version=${queryParameter.version}`;
    }
    history.replace(urlParams);
  }, [queryParameter]);

  React.useEffect(() => {
    if (appVersions && appVersions?.adminAppVersions?.length) {
      const rows = appVersions?.adminAppVersions?.filter((v: any) => {
        if (queryParameter.env !== null && queryParameter.env !== undefined && queryParameter?.env !== "" && +queryParameter?.env >= 0 && v.envID != +queryParameter?.env) {
          return false
        }
        return true
      })
      .map((el: any) => {
        return {
          id: el.id,
          name: el.name,
          description: el.description,
          version: el.version,
          forcedUpdate: el.forcedUpdate,
          createTime: setTimeWithTimeZone(moment.unix(el.createTime)).format('YYYY/MM/DD hh:mm'),
          deployAt: setTimeWithTimeZone(moment.unix(el.deployAt)).format('YYYY/MM/DD hh:mm'),
          app: appMap.hasOwnProperty(el.appID) ? appMap[el.appID] : "NOT DETECTED",
          platform: platformMap.hasOwnProperty(el.platform) ? platformMap[el.platform] : "NOT DETECTED",
          envID: envMap.hasOwnProperty(el.envID) ? envMap[el.envID] : "NOT DETECTED",
          serverUrl: el.serverUrl,
        }
      })
      .reduce((acc: any, cur: any) => {
        if (!acc[cur.version]) acc[cur.version] = [];
        acc[cur.version].push(cur);
        return acc
    }, {})
    setDataShow(rows)
    }
  }, [appVersions]);

  const handleChangeApp = async (event: any) => {
    //@ts-ignore
    setQueryParameter({
      ...queryParameter,
      appId: event?.target?.value,
    });
  };

  const handleSearch = (newValue: any) => {
    setQueryParameter({
      ...queryParameter,
      searchText: newValue,
    });
  };

  const onSearchKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      handleSearch(event.target.value);
    }
  };

  const onChangeInput = (event: any) => {
    const { value, name } = event.target;
  };

  const handleAddPost = () => {
    setOpenAdd(true)
  };

  const onAddVersion = async () => {
    if (
      !addVersion.appID || 
      !addVersion.deployAt || 
      !envMap[addVersion.envID] || 
      !platformMap[addVersion.platform] || 
      !addVersion.serverUrl || 
      !addVersion.version
    ) {
      return
    }
    if (dataShow[addVersion.version]) {
      let found = false
      appVersions?.adminAppVersions?.map((v: any) => {
        if (
          v.version == addVersion.version &&
          v.appID == addVersion.appID &&
          v.platform == addVersion.platformId &&
          v.envID == addVersion.envID
        ) {
          found = true
        }
      })
      if (found) {
        toast.error("Version already exists !!!", { autoClose: 5000 });
        return
      }
    }
    setLoading(true);
    
    addOrUpdateAppVersion({
      variables: {
        input: {
          id: addVersion.id,
          name: addVersion.name,
          description: addVersion.description,
          version: addVersion.version,
          forcedUpdate: addVersion.forcedUpdate,
          platformId: addVersion.platform,
          deployAt: Math.ceil(addVersion.deployAt/1000),
          appID: addVersion.appID,
          envId: addVersion.envID,
          serverUrl: addVersion.serverUrl,
        },
      },
    }).then((res)=>{
      setLoading(false);
      setAddVersion(defaultNewVersion)
      refetchAppVersions()
      handleClose()
      toast.success("Create Version Success !!!", { autoClose: 3000 });
    }).catch((err)=>{
      setLoading(false)
      toast.error("Something wrong !!!", { autoClose: 5000 });
    }).finally(()=>{
      setLoading(false);
    });
  }

  const handleClose = () => {
    setOpenAdd(false)
  }

  const onDeleteVersion = async (id: string) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      text: `You won't be able to revert this!`,
      showCancelButton: true,
      confirmButtonText: "Yes, del it!",
    });
    if (!result.isConfirmed) {
      return;
    }
    deleteAppVersion({
      variables: {
        input: {
          id,
        },
      },
    }).then((res)=>{
      setLoading(false);
      toast.success("Deleted !!!", { autoClose: 3000 });
    }).catch((err)=>{
      setLoading(false)
    }).finally(()=>{
      setLoading(false);
      refetchAppVersions()
    });
  }

  const onEditVersion = async (v: any) => {
    const foundVer = appVersions?.adminAppVersions?.find((ver: any) => ver.id == v.id)
    if (!foundVer) {
      return
    }
    setAddVersion({
      id: foundVer.id,
      name: foundVer.name,
      description: foundVer.description,
      version: foundVer.version,
      forcedUpdate: foundVer.forcedUpdate,
      platform: foundVer.platform,
      deployAt: foundVer.deployAt * 1000,
      appID: foundVer.appID,
      envID: foundVer.envID,
      serverUrl: foundVer.serverUrl,
    })
    setOpenAdd(true)
  }

  const onFilterEnv = (env: any) => {
    let realEnv: any = ""
    if (env !== "") {
      realEnv = +env
    }
    //@ts-ignore
    setQueryParameter({
      ...queryParameter,
      env: realEnv,
    });

  }

  return {
    dataShow,
    loading: loadingAppVersions || loading,
    handleAddPost,
    handleSearch,
    onSearchKeyDown,
    onChangeInput,
    // onChangeSelect,
    queryParameter,
    // handleChangeSort,
    handleChangeApp,
    onAddVersion,
    openAdd,
    handleClose,
    addVersion,
    setAddVersion,
    platformMap,
    envMap,
    onDeleteVersion,
    onEditVersion,
    onFilterEnv,
  };
};

export default useMainHook;
