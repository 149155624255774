import React from "react";
import {
  Checkbox,
} from "Components/base";
import TrashIcon from "Components/base/Icons/TrashIcon";
import { ReactComponent as EditIcon }  from "Components/base/Icons/EditIcon.svg";
import style from "./Item.module.scss";
type Item = {
    id: string,
    name: string,
    version: string,
    forcedUpdate: boolean,
    createTime: string,
    deployAt: string,
    app: string,
    platform: string,
    envID: string,
    serverUrl: string,
}
const columns = [
  {
    label: "Environment",
    cls: style["verion-env"],
  },
  {
    label: "App",
    cls: style["verion-app"],
  },
  {
    label: "Platform",
    cls: style["verion-platform"],
  },
  {
    label: "Deploy At",
    cls: style["verion-deploy"],
  },
  {
    label: 'Force Update',
    cls: style["verion-force"],
  },
  {
    label: "Create At",
    cls: style["verion-create"],
  },
  {
    label: "Server Url",
    cls: style["verion-url"],
  },
  {
    label: "Action",
    cls: style["verion-act"],
  }
]

const VersionItem = ({version, items, onDeleteVersion, onEditVersion }: any) => {
  return (<div className={style["versionItem"]}>
      <div className={style["version-title"]}>{version}</div>
      <div className={style["verion-row"] + ' ' + style["header"]}>
        {columns.map((el: any, idx) => {
          return (<div className={el.cls} key={'header-' + el.label}><b>{el.label}</b></div>)
        })}
      </div>
      <div>{items?.map((v: Item) => {
        return (<div className={style["verion-row"]} key={'verion-row-' + v.app + v.version + v.envID + v.platform}>
          <div className={style["verion-env"]}>{v.envID}</div>
          <div className={style["verion-app"]}>{v.app}</div>
          <div className={style["verion-platform"]}>{v.platform}</div>
          <div className={style["verion-deploy"]}>{v.deployAt}</div>
          <div className={style["verion-force"]}><Checkbox checked={v.forcedUpdate} disabled /></div>
          <div className={style["verion-create"]}>{v.createTime}</div>
          <div className={style["verion-url"]}>{v.serverUrl}</div>
          <div className={style["verion-act"]}>
            <div className={style["edit"]} onClick={() => onEditVersion(v)}><EditIcon/></div>
            <div onClick={() => onDeleteVersion(v.id)}><TrashIcon/></div>
          </div>
        </div>)
      })}</div>
    </div>
  )
}

export default VersionItem;