import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CONSTANTS } from "consts";
import { AdminAppContext } from "contexts";
import * as React from "react";
import { torem } from "styles/theme/toRem";
import { ReactComponent as AchievementIcon } from "./Icons/Achievement.svg";
import { ReactComponent as ChallengeIcon } from "./Icons/Challenge.svg";
import { ReactComponent as ContactMessageIcon } from './Icons/ContactMessage.svg';
import { ReactComponent as GameSettingIcon } from "./Icons/GameSetting.svg";
import { ReactComponent as GameShopGroupIcon } from "./Icons/GameShopGroup.svg";
import { ReactComponent as NoteIcon } from "./Icons/Note.svg";
import { ReactComponent as RowMachineIcon } from "./Icons/Row-Machine.svg";
import { ReactComponent as StripeIcon } from "./Icons/Stripe.svg";
import { ReactComponent as SupportIcon } from "./Icons/Support.svg";
import { ReactComponent as UserAdminIcon } from "./Icons/User-Admin.svg";
import { ReactComponent as UserIcon } from "./Icons/User.svg";
import { ReactComponent as SPIcon } from "./Icons/StructuredProgram.svg";
import MenuItemCollapse from "./MenuItemCollapse";

const NavUI: React.FunctionComponent<{
  clickMenu?: (pageId: number) => void;
}> = ({ clickMenu }) => {
  const {
    global: { current_page: currentPage },
  } = React.useContext(AdminAppContext);
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  return (
    <div className="menu-items">
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
      // subheader={
      //     <ListSubheader component="div" id="nested-list-subheader">
      //         Aviron dashboard
      //     </ListSubheader>
      // }
      >
        <div className="groupMenu">ADMIN</div>

        <MenuItemCollapse
          icon={<StripeIcon />}
          activeGroup={currentPage}
          nameGroup="Membership"
          idGroup="membership"
        >
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIP
              ? " active"
              : ""
              }`}
            onClick={() =>
              //@ts-ignore
              clickMenu(CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIP)
            }
          >
            <ListItemText primary="Management" disableTypography />
          </ListItem>
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION_CANCEL
              ? " active"
              : ""
              }`}
            onClick={() =>
              //@ts-ignore
              clickMenu(CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION_CANCEL)
            }
          >
            <ListItemText primary="Cancel" disableTypography />
          </ListItem>
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.PLAN ? " active" : ""
              }`}
            //@ts-ignore
            onClick={() => clickMenu(CONSTANTS.SIDEBAR_PAGEID.PLAN)}
          >
            <ListItemText primary="Plan" disableTypography />
          </ListItem>
        </MenuItemCollapse>

        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.MACHINE ? " active" : ""
            }`}
          onClick={
            //@ts-ignore
            () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.MACHINE)
          }
        >
          <ListItemIcon className="icon-item">
            <RowMachineIcon />
          </ListItemIcon>
          <ListItemText primary="Machine" disableTypography />
        </ListItem>
        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.USER ? " active" : ""
            }`}
          onClick={
            //@ts-ignore
            () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.USER)
          }
        >
          <ListItemIcon className="icon-item">
            <UserIcon />
          </ListItemIcon>
          <ListItemText primary="User" disableTypography />
        </ListItem>

        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.CONTACT_MESSAGE
            ? " active"
            : ""
            }`}
          //@ts-ignore
          onClick={() => clickMenu(CONSTANTS.SIDEBAR_PAGEID.CONTACT_MESSAGE)}
        >
          <ListItemIcon className="icon-item">
            <ContactMessageIcon />
          </ListItemIcon>
          <ListItemText primary="Contact Message" disableTypography />
        </ListItem>

        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.DEALER_LOCATION
            ? " active"
            : ""
            }`}
          onClick={() =>
            //@ts-ignore
            clickMenu(CONSTANTS.SIDEBAR_PAGEID.DEALER_LOCATION)
          }
        >
          <ListItemIcon className="icon-item">
            <i className="fa fa-map-marked-alt"></i>
          </ListItemIcon>
          <ListItemText primary="Dealer location" disableTypography />
        </ListItem>
        <MenuItemCollapse
          icon={<SupportIcon />}
          nameGroup={"Support"}
          idGroup={"support"}
          activeGroup={currentPage}
        >
          {/* Support Category Item */}
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.SUPPORT ? " active" : ""
              }`}
            onClick={
              //@ts-ignore
              () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.SUPPORT)
            }
          >
            {/* <ListItemIcon className="icon-item">
                            <i className="icon-megaphone" />
                        </ListItemIcon> */}
            <ListItemText primary="Support Category" disableTypography />
          </ListItem>
          {/* Support Category Item */}

          {/* Support POST Item */}
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.SUPPORT_POST
              ? " active"
              : ""
              }`}
            onClick={() =>
              //@ts-ignore
              clickMenu(CONSTANTS.SIDEBAR_PAGEID.SUPPORT_POST)
            }
          >
            {/* <ListItemIcon className="icon-item">
                            <i className="icon-megaphone" />
                        </ListItemIcon> */}
            <ListItemText primary="Support Post" disableTypography />
          </ListItem>
          {/* Support POST Item */}
        </MenuItemCollapse>
        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.PRESS ? " active" : ""
            }`}
          onClick={
            //@ts-ignore
            () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.PRESS)
          }
        >
          <ListItemIcon className="icon-item">
            <NoteIcon />
          </ListItemIcon>
          <ListItemText primary="Press" disableTypography />
        </ListItem>
        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.ADMIN ? " active" : ""
            }`}
          onClick={
            //@ts-ignore
            () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.ADMIN)
          }
        >
          <ListItemIcon className="icon-item">
            <UserAdminIcon />
          </ListItemIcon>
          <ListItemText primary="Admin" disableTypography />
        </ListItem>

        <div
          className="lineMenu"
          style={{ marginTop: torem(20), marginBottom: torem(5) }}
        ></div>
        <div className="groupMenu">GAME</div>
        <MenuItemCollapse
          icon={<GameShopGroupIcon />}
          nameGroup={"Game Beta User"}
          idGroup={"gameBetaUser"}
          activeGroup={currentPage}
        >
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_USER
              ? " active"
              : ""
              }`}
            onClick={
              //@ts-ignore
              () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_USER)
            }
          >
            <ListItemText primary="Beta User" disableTypography />
          </ListItem>
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_REQUEST
              ? " active"
              : ""
              }`}
            onClick={
              //@ts-ignore
              () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_REQUEST)
            }
          >
            <ListItemText primary="Beta Request" disableTypography />
          </ListItem>
        </MenuItemCollapse>
        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.ACHIEVEMENT
            ? " active"
            : ""
            }`}
          onClick={
            //@ts-ignore
            () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.ACHIEVEMENT)
          }
        >
          <ListItemIcon className="icon-item">
            <AchievementIcon />
          </ListItemIcon>
          <ListItemText primary="Achievement" disableTypography />
        </ListItem>
        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.MONTHLY_CHALLENGE
            ? " active"
            : ""
            }`}
          onClick={
            //@ts-ignore
            () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.MONTHLY_CHALLENGE)
          }
        >
          <ListItemIcon className="icon-item">
            <ChallengeIcon />
          </ListItemIcon>
          <ListItemText primary="Monthly Challenge" disableTypography />
        </ListItem>
        <MenuItemCollapse
          icon={<GameShopGroupIcon />}
          nameGroup={"Game Shop"}
          idGroup={"gameShop"}
          activeGroup={currentPage}
        >
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY
              ? " active"
              : ""
              }`}
            onClick={
              //@ts-ignore
              () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY)
            }
          >
            <ListItemText primary="Category" disableTypography />
          </ListItem>
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT
              ? " active"
              : ""
              }`}
            onClick={
              //@ts-ignore
              () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT)
            }
          >
            <ListItemText primary="Product" disableTypography />
          </ListItem>
        </MenuItemCollapse>
        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.STRUCTURED_PROGRAM
            ? " active"
            : ""
            }`}
          onClick={
            //@ts-ignore
            () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.STRUCTURED_PROGRAM)
          }
        >
          <ListItemIcon className="icon-item">
            <SPIcon />
          </ListItemIcon>
          <ListItemText primary="Structured Program" disableTypography />
        </ListItem>
        {/* <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.GAME_SETTING
            ? " active"
            : ""
            }`}
          onClick={
            //@ts-ignore
            () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.GAME_SETTING)
          }
        >
          <ListItemIcon className="icon-item">
            <GameSettingIcon />
          </ListItemIcon>
          <ListItemText primary="Game Setting" disableTypography />
        </ListItem> */}

        {/* <MenuItemCollapse
          icon={<StripeIcon />}
          activeGroup={currentPage}
          nameGroup="Stripe Membership"
          idGroup="membership"
        >
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION
              ? " active"
              : ""
              }`}
            onClick={() =>
              //@ts-ignore
              clickMenu(CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION)
            }
          >
            <ListItemText primary="Management" disableTypography />
          </ListItem>
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION_CANCEL
              ? " active"
              : ""
              }`}
            onClick={() =>
              //@ts-ignore
              clickMenu(CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION_CANCEL)
            }
          >
            <ListItemText primary="Cancel" disableTypography />
          </ListItem>
          <ListItem
            button
            className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.PLAN ? " active" : ""
              }`}
            //@ts-ignore
            onClick={() => clickMenu(CONSTANTS.SIDEBAR_PAGEID.PLAN)}
          >
            <ListItemText primary="Plan" disableTypography />
          </ListItem>
        </MenuItemCollapse> */}
        <MenuItemCollapse
          icon={<GameShopGroupIcon />}
          nameGroup={"Game Info"}
          idGroup={"gameInfo"}
          activeGroup={currentPage}
        >
          <ListItem
            button
            className={`menu-item${
              currentPage === CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_TYPE
                ? " active"
                : ""
            }`}
            onClick={
              //@ts-ignore
              () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_TYPE)
            }
          >
            <ListItemText primary="Game Types" disableTypography />
          </ListItem>

          <ListItem
            button
            className={`menu-item${
              currentPage === CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_OPTION
                ? " active"
                : ""
            }`}
            onClick={
              //@ts-ignore
              () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_OPTION)
            }
          >
            <ListItemText primary="Game Options" disableTypography />
          </ListItem>

          <ListItem
            button
            className={`menu-item${
              currentPage === CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_CATEGORIES
                ? " active"
                : ""
            }`}
            onClick={
              //@ts-ignore
              () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_CATEGORIES)
            }
          >
            <ListItemText primary="Game Categories" disableTypography />
          </ListItem>

          <ListItem
            button
            className={`menu-item${
              currentPage === CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_INFO
                ? " active"
                : ""
            }`}
            onClick={
              //@ts-ignore
              () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_INFO)
            }
          >
            <ListItemText primary="Game Info" disableTypography />
          </ListItem>
        </MenuItemCollapse>
        <ListItem
          button
          className={`menu-item${currentPage === CONSTANTS.SIDEBAR_PAGEID.APP_VESION
            ? " active"
            : ""
            }`}
          onClick={
            //@ts-ignore
            () => clickMenu(CONSTANTS.SIDEBAR_PAGEID.APP_VESION)
          }
        >
          <ListItemIcon className="icon-item">
            <SPIcon />
          </ListItemIcon>
          <ListItemText primary="App Versions" disableTypography />
        </ListItem>
      </List>
    </div>
  );
};
export default NavUI;
