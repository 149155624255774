
import { useGetGameInfoDataByAppQuery, useAdminGameTypesQuery, useGetAppsQuery, useAdminSyncGameDataMutation } from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";

import { ENV } from 'scripts/apps/environment'
const ROWS_PER_PAGE = 20

const context = {}

type gameInfoType = {
    ID: string
    Name: string
    ShortDescription: string
    LongDescription: string
    GameTypeID: number
    DisplayTimeInfo: string
    GameDuration: number
    GameplayConfigFile: string
    GameInfoCustomConfig: object
    GameOptionID: string
    Thumbnail: string
    Difficulty: string
    Tags: string[]
    AllowUser: string[]
    open: boolean
    textEdit: boolean
    edited: boolean
}
type gameTypesType = {
    ID: number
    Name: string
}

const useGameInfoHook = () => {
  const [adminSyncGameData] = useAdminSyncGameDataMutation()

      const [currentPage, setCurrentPage] = React.useState(0);
      const [totalPage, setTotalPage] = React.useState(0);
      const [gameInfo, setGameInfo] = React.useState([] as gameInfoType[]);
      const [currentApp, setCurrentApp] = React.useState(1);
      const [currentGt, setCurrentGt] = React.useState(-1);
      const { data, loading, refetch } = useGetGameInfoDataByAppQuery({
        variables: {
          input: {
            appId: currentApp || 1,
          }
        },
          context
        });

      const { data: dataApp } = useGetAppsQuery({
        context
      });
      const {
        data: dataGT
      } = useAdminGameTypesQuery({
        variables: {
          input: {
            appId: Number(currentApp || 1),
          }
        },
      });
    const [gameTypes, setGameTypes] = React.useState([] as gameTypesType[]);
    const [apps, setApps] = React.useState([] as gameInfoType[]);

  
    React.useMemo(() => {
      if (dataApp && dataApp.adminGameApps) {
        setApps(dataApp.adminGameApps.map((e: any) => {
              return {
                  ID: e.id,
                  Name: e.name,
              }
          }))
      }
    }, [dataApp]);

    React.useMemo(() => {
      if (data && data.getGameInfoDataByApp) {
        let beginData = data.getGameInfoDataByApp
        if (currentGt != -1) {
          beginData = beginData.filter((el: any) => el.GameTypeID == currentGt)
        }
        const gi = beginData.slice(0,20).map((e: any) => {
          return {
            ID: e.ID,
            Name: e.Name,
            ShortDescription: e.ShortDescription,
            LongDescription: e.LongDescription,
            GameTypeID: e.GameTypeID,
            DisplayTimeInfo: e.DisplayTimeInfo,
            GameDuration: e.GameDuration,
            GameplayConfigFile: e.GameplayConfigFile,
            GameInfoCustomConfig: e.GameInfoCustomConfig,
            GameOptionID:  e.GameOptionID,
            Thumbnail: e.Thumbnail,
            Difficulty: e.Difficulty,
            Tags: e.Tags,
            AllowUser: e.AllowUser,
            open: false,
            textEdit: false,
            edited: false,
          }
        })
        const ttPage = Math.ceil(beginData.length / ROWS_PER_PAGE)
        setTotalPage(ttPage) 
        setGameInfo(gi)
      }
    }, [data]);

    React.useMemo(() => {
        if (dataGT && dataGT.adminGameTypes ) {
            setGameTypes(dataGT.adminGameTypes.sort((a: any, b: any) => a.id - b.id).map((e: any) => {
                return {
                    ID: e.id,
                    Name: e.name,
                }
            }))
        }
    }, [dataGT]);

    const columns = [
        {
            disablePadding: false,
            label: "ID",
            id: "ID",
            width: "15%"
        },
        {
            label: "Name",
            id: "Name",
            width: "25%"
        },
        {
            label: "GameTypeID",
            id: "GameTypeID",
            width: "10%"
        },
        {
            label: "ConfigFile",
            id: "ConfigFile",
            width: "15%"
        },
        {
            label: "GameOptionID",
            id: "GameOptionID",
            width: "15%"
        },
        {
            label: "Thumbnail",
            id: "Thumbnail",
            width: "15%"
        },
      ]

    const onChangeApp = (event: any) => {
      const {value} = event.target

      setCurrentApp(+value)
      setCurrentPage(0)
      refetch()
    }

    const handleSearch = (textInput: string) => {
      if (data && data.getGameInfoDataByApp) {
        setCurrentPage(0)
        const dataFiltered = data.getGameInfoDataByApp.map((e: any) => {
          if (!e.Name.toLowerCase().includes(textInput.toLowerCase())) {
            return false
          }
          return {
            ID: e.ID,
            Name: e.Name,
            ShortDescription: e.ShortDescription,
            LongDescription: e.LongDescription,
            GameTypeID: e.GameTypeID,
            DisplayTimeInfo: e.DisplayTimeInfo,
            GameDuration: e.GameDuration,
            GameplayConfigFile: e.GameplayConfigFile,
            GameInfoCustomConfig: e.GameInfoCustomConfig,
            GameOptionID:  e.GameOptionID,
            Thumbnail: e.Thumbnail,
            Difficulty: e.Difficulty,
            Tags: e.Tags,
            AllowUser: e.AllowUser,
            open: false,
            textEdit: false,
            edited: false,
          }
        }).filter(Boolean)
        const dataGi = dataFiltered.slice((currentPage) * 20, (currentPage + 1) * 20)

        setGameInfo(dataGi)
        const ttPage = Math.ceil(dataFiltered.length / ROWS_PER_PAGE)
        setTotalPage(ttPage) 
      }
    }


    const filterType = (event: any) => {
      const {value} = event.target
      setCurrentGt(+value)
      if (data && data.getGameInfoDataByApp) {
        setCurrentPage(0)
        const dataFiltered = data.getGameInfoDataByApp.map((e: any) => {
          if (+value != -1 && !(e.GameTypeID == +value)) {
            return false
          }
          return {
            ID: e.ID,
            Name: e.Name,
            ShortDescription: e.ShortDescription,
            LongDescription: e.LongDescription,
            GameTypeID: e.GameTypeID,
            DisplayTimeInfo: e.DisplayTimeInfo,
            GameDuration: e.GameDuration,
            GameplayConfigFile: e.GameplayConfigFile,
            GameInfoCustomConfig: e.GameInfoCustomConfig,
            GameOptionID:  e.GameOptionID,
            Thumbnail: e.Thumbnail,
            Difficulty: e.Difficulty,
            Tags: e.Tags,
            AllowUser: e.AllowUser,
            open: false,
            textEdit: false,
            edited: false,
          }
        }).filter(Boolean)
        const dataGi = dataFiltered.slice((currentPage) * 20, (currentPage + 1) * 20)
        setGameInfo(dataGi)
        const ttPage = Math.ceil(dataFiltered.length / ROWS_PER_PAGE)
        setTotalPage(ttPage) 
      }
    }

    const handleChangePage = (page: number) => {
      setCurrentPage(page)
      if (data && data.getGameInfoDataByApp) {
        const dataGi = data.getGameInfoDataByApp.map((e: any) => {
          if (+currentGt != -1 && e.GameTypeID !== +currentGt) {
            return false
          }
          return e
        })
        .filter(Boolean)
        .slice(page * 20, (page + 1) * 20)
        .map((e: any) => {
          return {
            ID: e.ID,
            Name: e.Name,
            ShortDescription: e.ShortDescription,
            LongDescription: e.LongDescription,
            GameTypeID: e.GameTypeID,
            DisplayTimeInfo: e.DisplayTimeInfo,
            GameDuration: e.GameDuration,
            GameplayConfigFile: e.GameplayConfigFile,
            GameInfoCustomConfig: e.GameInfoCustomConfig,
            GameOptionID:  e.GameOptionID,
            Thumbnail: e.Thumbnail,
            Difficulty: e.Difficulty,
            Tags: e.Tags,
            AllowUser: e.AllowUser,
            open: false,
            textEdit: false,
            edited: false,
          }
        })

        setGameInfo(dataGi)
      }
    }


    const onRequestSync = async () => {
      await adminSyncGameData({
        variables: {
          input: {
            appId: currentApp,
          }
        },
      }).then((res)=>{
        toast.success("Sync Game Info Success", { autoClose: 3000 });
      }).catch((err)=>{
        //   errorHandle(err, formik)
        toast.error("Sync Game Info Error", { autoClose: 3000 });
      })
    }


    return {
        loading,
        gameInfo,
        columns,
        apps,
        currentApp,
        gameTypes,
        onChangeApp,
        handleSearch,
        handleChangePage,
        currentPage,
        totalPage,
        filterType,
        currentGt,
        onRequestSync,
    };
};

export default useGameInfoHook;
