import style from "./MenuItemCollapse.module.scss";
import React, { useEffect, useLayoutEffect } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { CONSTANTS } from "consts";

interface MenuItemCollapseProps {
    children?: any;
    icon?: any;
    openDefault?: any;
    nameGroup?: string;
    activeGroup: any;
    idGroup: any;
}
const activeCollapseItems = {
    membership: [
        CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION,
        CONSTANTS.SIDEBAR_PAGEID.SUBSCRIPTION_CANCEL,
        CONSTANTS.SIDEBAR_PAGEID.MEMBERSHIP,
        CONSTANTS.SIDEBAR_PAGEID.PLAN,
    ],
    support: [
        CONSTANTS.SIDEBAR_PAGEID.SUPPORT,
        CONSTANTS.SIDEBAR_PAGEID.SUPPORT_POST,
    ],
    gameShop: [
        CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_CATEGORY,
        CONSTANTS.SIDEBAR_PAGEID.GAME_SHOP_PRODUCT,
      ],
    gameBetaUser: [
    CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_USER,
    CONSTANTS.SIDEBAR_PAGEID.GAME_BETA_REQUEST,
    ],
    gameInfo: [
        CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_TYPE,
        CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_OPTION,
        CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_CATEGORIES,
        CONSTANTS.SIDEBAR_PAGEID.GAME_INFO_GAME_INFO,
    ],
};
const MenuItemCollapse = ({
    children,
    icon,
    activeGroup,
    nameGroup,
    idGroup,
}: MenuItemCollapseProps) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (expanded: boolean) => {
        setExpanded(!expanded);
    };
    useLayoutEffect(() => {
        //@ts-ignore
        const array = activeCollapseItems[`${idGroup}`];
        if (array.includes(activeGroup) && activeGroup > 0) {
            setExpanded(true);
        } else {
            setExpanded(false);
        }
    }, [activeGroup]);
    return (
        <div className={style["MenuItemCollapse"]}>
            <div className={"MenuItemCollapse"}>
                <Accordion
                    expanded={expanded}
                    onChange={() => handleChange(expanded)}
                >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <ListItemIcon className="icon-item">
                            {icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={nameGroup ? nameGroup : "Support"}
                            disableTypography
                        />
                    </AccordionSummary>
                    <AccordionDetails>{children}</AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
};

export default MenuItemCollapse;
