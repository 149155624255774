
import { useHistory } from "react-router-dom";
import { useGetGameCategoriesDataQuery, useAddOrUpdateGameCategoriesMutation, useGetGameInfoDataQuery, useGetAppsQuery, useDeleteGameCategoriesMutation } from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { ENV } from 'scripts/apps/environment'

const context = {}

type gameCategoriesType = {
    ID: string
    AppID: number
    Name: string
    Description: string
    Container: string
    Thumbnail: string
    SortOrder: number
    open: boolean
    textEdit: boolean
    edited: boolean
}

type gameInfoType = {
    ID: string
    Name: string
}

const useGameCategoriesHook = () => {
    const history = useHistory();
    const [updateGameCategories] = useAddOrUpdateGameCategoriesMutation()
    const [deleteGameCategories] = useDeleteGameCategoriesMutation()

    const { data: dataApp } = useGetAppsQuery({
      context
    });
    const { data, loading } = useGetGameCategoriesDataQuery({
      context
    });
    const { data: gameInfoList } = useGetGameInfoDataQuery({
      context
    });
    const [gameCategories, setGameCategories] = React.useState([] as gameCategoriesType[]);
    const [currentApp, setCurrentApp] = React.useState(1);

    const [gameInfo, setGameInfo] = React.useState([] as gameInfoType[]);
    const [apps, setApps] = React.useState([] as gameInfoType[]);

    //   const [currentGameCategories, setCurrentGameCategories] = React.useState([] as gameCategoriesType[]);
    React.useMemo(() => {
        if (data && data.getGameCategoriesData) {
            setGameCategories(data.getGameCategoriesData[currentApp].map((e: any) => {
                return {
                    ID: e.ID,
                    Name: e.Name,
                    Description: e.Description,
                    Container: e.Container.join(','),
                    Thumbnail: e.Thumbnail,
                    SortOrder: e.SortOrder,
                    open: false,
                    textEdit: false,
                    edited: false,
                }
            }))
        }
    }, [data]);
    React.useMemo(() => {
        if (gameInfoList && gameInfoList.getGameInfoData) {
            setGameInfo(gameInfoList.getGameInfoData[currentApp].map((e: any) => {
                return {
                    ID: e.ID,
                    Name: e.Name,
                }
            }))
        }
    }, [gameInfoList]);

  
    React.useMemo(() => {
        if (dataApp && dataApp.adminGameApps) {
          setApps(dataApp.adminGameApps.map((e: any) => {
                return {
                    ID: e.id,
                    Name: e.name,
                }
            }))
        }
    }, [dataApp]);

    const columns = [
        {
            disablePadding: false,
            label: "ID",
            id: "ID",
            width: "50px"
        },
        {
            label: "Name",
            id: "Name",
            width: "120px"
        },
        {
            label: "Description",
            id: "Description",
            width: "200px"
        },
        {
            label: "Container",
            id: "Container",
        },
        {
            label: "Thumbnail",
            id: "Thumbnail",
            width: "120px"
        },
        {
            label: "Sort",
            id: "SortOrder",
            width: "40px"
        },
      ]

    const onChangeApp = (event: any) => {
        const {value} = event.target
        setCurrentApp(value)
        if (data && data.getGameCategoriesData && data.getGameCategoriesData[value]) {
            let cates = data.getGameCategoriesData[value].map((e: any) => {
                return {
                    ID: e.ID,
                    Name: e.Name,
                    Description: e.Description,
                    Container: (e.Container || []).join(','),
                    Thumbnail: e.Thumbnail,
                    SortOrder: e.SortOrder,
                    open: false,
                    textEdit: false,
                    edited: false,
                }
            })
            setGameCategories(cates)
        } else {
            setGameCategories([])
        }
        if (gameInfoList && gameInfoList.getGameInfoData && gameInfoList.getGameInfoData[value]) {
            setGameInfo(gameInfoList.getGameInfoData[value].map((e: any) => {
                return {
                    ID: e.ID,
                    Name: e.Name,
                }
            }))
        } else {
            setGameInfo([])
        }
    }

    const handleSelectGame = (name: any, value: any) => {
        console.log('handleSelectGame', {name, value})
        const dataToSet = gameCategories.map((e: gameCategoriesType) => {
            if (e.ID == name) {
                e.Container = value.map((el: any) => el.value).join(',')
                e.edited = true
            }
            return e
        })
        setGameCategories(dataToSet)
    }

    const onSwitch = (ev: any, checked: boolean) => {
        const { name } = ev.target;
        const dataToSet = gameCategories.map((e: gameCategoriesType) => {
            if (e.ID == name) {
                e.textEdit = checked
                e.edited = true
            }
            return e
        })
        setGameCategories(dataToSet)
    }

    const onEditContainerText = (ev: any) => {
        const { name, value } = ev.target;
        const dataToSet = gameCategories.map((e: gameCategoriesType) => {
            if (e.ID == name) {
                e.Container = value
                e.edited = true
            }
            return e
        })
        setGameCategories(dataToSet)
    }

    const onChangeName = (ev: any) => {
        const { name, value } = ev.target;
        const dataToSet = gameCategories.map((e: gameCategoriesType) => {
            if (e.ID == name) {
                e.Name = value
                e.edited = true
            }
            return e
        })
        setGameCategories(dataToSet)
    }

    const onChangeDesc = (ev: any) => {
        const { name, value } = ev.target;
        const dataToSet = gameCategories.map((e: gameCategoriesType) => {
            if (e.ID == name) {
                e.Description = value
                e.edited = true
            }
            return e
        })
        setGameCategories(dataToSet)
    }

    const onChangeThumbnail = (ev: any) => {
        const { name, value } = ev.target;
        const dataToSet = gameCategories.map((e: gameCategoriesType) => {
            if (e.ID == name) {
                e.Thumbnail = value
                e.edited = true
            }
            return e
        })
        setGameCategories(dataToSet)
    }

    const onChangeSortOrder = (ev: any) => {
        const { name, value } = ev.target;
        const dataToSet = gameCategories.map((e: gameCategoriesType) => {
            if (e.ID == name) {
                e.SortOrder = parseInt(value)
                e.edited = true
            }
            return e
        })
        setGameCategories(dataToSet)
    }
    
    const onAddNewCategory = () => {
        const newSO = Math.max(...gameCategories.map(e => e.SortOrder)) + 1
        const maxId = Math.max(...gameCategories.map(e => {
            const matches = e.ID.match(/\d+/)
            if (matches) {
                return +matches[0]
            }
            // const toNum = (e?.ID || '0').match(/\d+/)[0]
            return 0
        })) + 1 
        const newCate: gameCategoriesType = {
            ID: 'Cate_' + maxId,
            AppID: currentApp,
            Name: '',
            Description: '',
            Container: '',
            Thumbnail: '',
            SortOrder: newSO,
            open: false,
            textEdit: false,
            edited: false,
        }
        setGameCategories([newCate, ...gameCategories])
    }

    const onSaveChange = async () => {
        const dataToSave = gameCategories.filter(e => e.edited).map(e => {
            return {
                ID: e.ID,
                AppId: currentApp,
                Name: e.Name,
                Description: e.Description,
                Container: e.Container,
                Thumbnail: e.Thumbnail,
                SortOrder: e.SortOrder,
            }
        })
        if (dataToSave.length) {
            const dataSaved = await updateGameCategories({
                variables: {
                    input: dataToSave,
                },
            })
            if ((dataSaved?.data?.adminAddOrUpdateGameCategories || []).length == dataToSave.length) {
                toast.success("Update Game Categories success");
            }
        }
    }

    const onDeleteGameCategories = async (id: string) => {
        const result = await Swal.fire({
          title: "Are you sure?",
          text: "This action cannot be undone!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        });
        if (!result.isConfirmed) {
          return;
        }
        const {data} = await deleteGameCategories({
            variables: {
                input: {
                    "appId": currentApp,
                    "ids": [id]
                },
            },
        })
        if (data?.adminDeleteGameCategories == null) {
            const dataToSet = gameCategories.filter((e: gameCategoriesType) => e.ID != id)
            setGameCategories(dataToSet)
            toast.success("Deleted !!!");
        } else {
            toast.error("ERROR !!!");
        }

    }

    const handleClose = (id: string, open: boolean) => {
        const dataToSet = gameCategories.map((e: gameCategoriesType) => {
            if (e.ID == id) {
                e.open = open
            }
            return e
        })
        setGameCategories(dataToSet)
    }

    return {
        loading,
        gameCategories,
        gameInfo,
        columns,
        apps,
        currentApp,
        onChangeApp,
        handleSelectGame,
        onSwitch,
        onEditContainerText,
        onChangeName,
        onChangeDesc,
        onSaveChange,
        onChangeThumbnail,
        onChangeSortOrder,
        onAddNewCategory,
        onDeleteGameCategories,
        handleClose,
    };
};

export default useGameCategoriesHook;
