
import { useHistory } from "react-router-dom";
import { useAdminGameTypesQuery, useAddOrUpdateGameTypesMutation } from "services/graphql/";
import * as React from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { ENV } from 'scripts/apps/environment'

const context = {}

type gameTypesType = {
    ID: number
    Name: string
    GameMode: string[]
    NumberPlayer: number
    HasHighscore: number
    open: boolean
    edited: boolean
}

const useGameTypesHook = () => {
    const history = useHistory();
    const [updateGameTypes] = useAddOrUpdateGameTypesMutation()
    const { data, loading } = useAdminGameTypesQuery({
        context
      });
    const [gameTypes, setGameTypes] = React.useState([] as gameTypesType[]);
    React.useMemo(() => {
        if (data && data.adminGameTypes?.length) {
            const ggt = data.adminGameTypes.map((e: any) => {
                return {
                  ID: e.id,
                  Name: e.name,
                  GameMode: e.gameMode,
                  NumberPlayer: e.numberPlayer,
                  HasHighscore: e.hasHighscore,
                  open: false,
                  edited: false,
                }
            }).sort((a: gameTypesType, b: gameTypesType) => b.ID - a.ID)
            setGameTypes(ggt)
        }
    }, [data]);

    const columns = [
        {
            label: "ID",
            id: "ID",
            width: "50px"
        },
        {
            label: "Name",
            id: "Name",
            width: "200px"
        },
        {
            label: "GameMode",
            id: "GameMode",
        },
        {
            label: "NumberPlayer",
            id: "NumberPlayer",
            width: "100px"
        },
        {
            label: "HasHighscore",
            id: "HasHighscore",
            width: "100px"
        }
      ]

    const handleSelectGameMode = (name: any, value: any) => {
      const dataToSet = gameTypes.map((e: gameTypesType) => {
          if (e.ID == name) {
              e.GameMode = value.map((el: any) => el.value)
              e.edited = true
          }
          return e
      })
      setGameTypes(dataToSet)
  }

  const addDummyGameType = () => {
    const newGtId = Math.max(...gameTypes.map(e => e.ID)) + 1
    const newGt: gameTypesType = {
        ID: newGtId,
        Name: "",
        GameMode: ["SinglePlayer"],
        NumberPlayer: 1,
        HasHighscore: 1,
        open: false,
        edited: true,
    } 
    setGameTypes([newGt, ...gameTypes])
  }

  const onChangeName = (ev: any) => {
      const { name, value } = ev.target;
      const dataToSet = gameTypes.map((e: gameTypesType) => {
          if (e.ID == +name) {
              e.Name = value
              e.edited = true
          }
          return e
      })
      setGameTypes(dataToSet)
  }

  const onChangeNumberPlayer = (ev: any) => {
      const { name, value } = ev.target;
      const dataToSet = gameTypes.map((e: gameTypesType) => {
          if (e.ID == +name) {
              e.NumberPlayer = +value
              e.edited = true
          }
          return e
      })
      setGameTypes(dataToSet)
  }

  const onChangeHasHighscore = (ev: any) => {
      const { name, value } = ev.target;
      const dataToSet = gameTypes.map((e: gameTypesType) => {
          if (e.ID == +name) {
              e.HasHighscore = +value
              e.edited = true
          }
          return e
      })
      setGameTypes(dataToSet)
  }

  const onSaveChange = async () => {
    const dataToSave = gameTypes.filter(e => e.edited).map(e => {
        return {
          ID: e.ID,
          Name: e.Name,
          GameMode: e.GameMode,
          NumberPlayer: +e.NumberPlayer,
          HasHighscore: +e.HasHighscore,
        }
    }).filter(e => !!e.Name)
    if (!dataToSave.length) {
        toast.error("Nothing changed !!!")
        return
    }
    const result = await Swal.fire({
        title: "Are you sure?",
        text: "this action cannot be undone!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
    });
    if (!result.isConfirmed) {
        return;
    }
      if (dataToSave.length) {
          const dataSaved = await updateGameTypes({
              variables: {
                  input: dataToSave,
              },
          })
          if ((dataSaved?.data?.adminAddOrUpdateGameTypes || []).length == dataToSave.length) {
              toast.success("Update Game Types success");
          }
      }
  }

    return {
        loading,
        gameTypes,
        columns,
        handleSelectGameMode,
        addDummyGameType,
        onChangeName,
        onChangeNumberPlayer,
        onChangeHasHighscore,
        onSaveChange,
    };
};

export default useGameTypesHook;
