import React from "react";
import { Route, Switch } from "react-router-dom";
import { CONSTANTS } from "consts";
import Main from "./Main/Main";

const AppVersion: React.FunctionComponent = () => {
    return (
        <div>
            <Switch>
                <Route
                    path={CONSTANTS.NAVIGATION_URL.APP_VESION}
                    component={Main}
                />
            </Switch>
        </div>
    );
};
export default AppVersion;
