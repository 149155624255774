import { Button, Grid, MenuItem, Select, TextField, Checkbox, InputLabel,
  Dialog,
  DialogContent,
  DialogTitle, } from "Components/base";
import { RingLoading } from "Components/RingLoading/RingLoading";
import React from "react";
import useMainHook from "./Main.hook";
import AddIcon from "Components/base/Icons/AddIcon";
import VersionItem from "Components/AppVersion/Item"
import { DatePicker } from "Components/DatePicker/DatePicker";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import { ReactComponent as SaveIcon } from "Components/base/Icons/SaveIcon.svg";
import style from './Main.module.scss'
import { torem } from "styles/theme/toRem";
import moment from "moment-timezone";

const apps = [{
  id: 1,
  name: 'Aviron Rower'
}, {
  id: 14,
  name: 'C2'
}, {
  id: 16,
  name: 'Aviron Rower Go'
}, {
  id: 32,
  name: 'MyAviron companion'
}, {
  id: 64,
  name: 'Water Rower'
}, {
  id: 128,
  name: 'Aviron Bike'
}, {
  id: 256,
  name: 'Aviron Bike Go'
}, {
  id: 512,
  name: 'Aviron Treadmill'
}, {
  id: 1024,
  name: 'Aviron Treadmill Go'
}]

const Main: React.FunctionComponent = () => {
  const {
    dataShow,
    loading,
    handleAddPost,
    queryParameter,
    handleChangeApp,
    onAddVersion,
    openAdd,
    handleClose,
    addVersion,
    setAddVersion,
    platformMap,
    envMap,
    onDeleteVersion,
    onEditVersion,
    onFilterEnv,

  } = useMainHook();
  const onChange = (k: any, val: any) => {
    const newVer = Object.assign({}, addVersion, {[k]: val})
    setAddVersion(newVer)
  }
  return (
    <div >
      <Grid
        container
        spacing={2}
        className="headerToolbarGrid"
        alignItems={"center"}
      >
        <Grid item>
          <div className="aviron-title">App Version Management</div>
        </Grid>
        <Grid item xs={12} md={6} lg={9} className="buttonGroup">
          <Grid item>
            <Select
              name="app"
              // placeholder={hidePlaceholder ? null : "Select a App"}
              value={queryParameter.appId}
              onChange={handleChangeApp}
              fullWidth
              style={{minWidth: '180px'}}
            >
              {apps.map((item: any, key: any) => {
                return (
                  <MenuItem value={item.id} key={'app-select-'+ item.id}>{item.name}</MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item>
            <Select
              name="currentEnv"
              placeholder={"Select Environment"}
              value={queryParameter.env}
              onChange={(event:any) => onFilterEnv(event?.target?.value)}
              fullWidth
              style={{minWidth: '180px'}}
            >
              {Object.keys(envMap).map((k: any) => {
                return (
                  <MenuItem value={k} key={'current-env-select-'+ k}>{envMap[k]}</MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={handleAddPost}
              startIcon={<AddIcon />}
              style={{ minWidth: "6.250vw", textTransform: 'uppercase' }}
            >
              ADD Version
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div className={style["AppVersionWrapper"]}>
        <div className={style["verions"]}>
          {
            Object.keys(dataShow).map(k => {
              return (<VersionItem version={k} items={dataShow[k]} onDeleteVersion={onDeleteVersion} onEditVersion={onEditVersion} key={"version-item-" + k} />)
            })
          }
        </div>
      </div>
      {loading ? <RingLoading /> : null}
      <Dialog
        open={openAdd}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{width: "1200px !important", maxWidth: "1200px !important"}} 
    >
      <DialogTitle
          id="alert-dialog-title"
      >
        Add Version
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={torem(16)}
          className={style["m-b-20"]}
        >
          <Grid item xs={6}>
            <TextField
              required
              id="name"
              label="Name"
              placeholder="Enter name"
              fullWidth
              name="name"
              margin="normal"
              variant="outlined"
              error={!addVersion.name}
              onChange={(event:any) => onChange('name', event?.target?.value)}
              value={addVersion.name}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="description"
              label="Description"
              placeholder="Enter description"
              fullWidth
              name="description"
              margin="normal"
              variant="outlined"
              error={!addVersion.description}
              onChange={(event:any) => onChange('description', event?.target?.value)}
              value={addVersion.description}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              id="version"
              label="Version"
              placeholder="Enter version"
              fullWidth
              name="version"
              margin="normal"
              variant="outlined"
              error={!addVersion.version}
              onChange={(event:any) => onChange('version', event?.target?.value)}
              value={addVersion.version}
            />
          </Grid>
          <Grid item xs={6} style={{marginTop: '15px'}}>
            <Checkbox
              required
              id="forcedUpdate"
              label="forcedUpdate"
              name="forcedUpdate"
              onChange={(event:any) => onChange('forcedUpdate', event?.target?.checked)}
              checked={addVersion.forcedUpdate}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel
              htmlFor="outlined-machine-type"
              required
              className={style["label-title"]}
            >
              Platform
            </InputLabel>
            <Select
              name="platform"
              // placeholder={hidePlaceholder ? null : "Select a App"}
              value={addVersion.platform}
              onChange={(event:any) => onChange('platform', +event?.target?.value)}
              fullWidth
              style={{minWidth: '180px'}}
            >
              {Object.keys(platformMap).map((k: any) => {
                  return (
                    <MenuItem value={k} key={'platform-select-'+ k}>{platformMap[k]}</MenuItem>
                  );
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel
              htmlFor="outlined-machine-type"
              required
              className={style["label-title"]}
            >
              Machine
            </InputLabel>
            <Select
              name="appID"
              // placeholder={hidePlaceholder ? null : "Select a App"}
              value={addVersion.appID}
              onChange={(event:any) => onChange('appID', +event?.target?.value)}
              fullWidth
              style={{minWidth: '180px'}}
            >
              {apps.map((item: any) => {
                  return (
                    <MenuItem value={item.id} key={'appID-select-'+ item.id}>{item.name}</MenuItem>
                  );
              })}
            </Select>
          </Grid>
          <Grid item xs={6}>
            <InputLabel
              htmlFor="outlined-machine-type"
              required
              className={style["label-title"]}
            >
              Environment
            </InputLabel>
            <Select
              name="envID"
              // placeholder={hidePlaceholder ? null : "Select a App"}
              value={addVersion.envID}
              onChange={(event:any) => onChange('envID', +event?.target?.value)}
              fullWidth
              style={{minWidth: '180px'}}
            >
              {Object.keys(envMap).map((k: any) => {
                  return (
                    <MenuItem value={k} key={'platform-select-'+ k}>{envMap[k]}</MenuItem>
                  );
              })}
            </Select>
          </Grid>
          <Grid item xs={6} style={{marginTop: '20px'}}>
            <DatePicker
              label="Deploy Time"
              value={addVersion.deployAt}
              name="deployAt"
              onChange={(name: any, date: any) => onChange(name, moment(date).utc(true).unix()*1000)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              id="serverUrl"
              label="Server Url"
              placeholder="Enter Server Url"
              fullWidth
              name="serverUrl"
              margin="normal"
              variant="outlined"
              error={!addVersion.serverUrl}
              onChange={(event:any) => onChange('serverUrl', event?.target?.value)}
              value={addVersion.serverUrl}
            />
          </Grid>
        </Grid>
        <Grid item style={{marginTop: '15px'}}>
          <Button
            variant="contained"
            onClick={handleClose}
            style={{ minWidth: torem(120), marginRight: '20px', backgroundColor: 'black' }}
          >
              Cancel
          </Button>
          <ButtonWithLoading
            variant="contained"
            loading={loading}
            type="submit"
            onClick={onAddVersion}
            startIcon={<SaveIcon />}
            style={{ minWidth: torem(120) }}
          >
            Save
          </ButtonWithLoading>
        </Grid>
      </DialogContent>
      </Dialog>
    </div>
  );
};

export default Main;
