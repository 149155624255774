import React, { useEffect, useState, Suspense } from "react";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectAutoComplete,
  TextField,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
} from "Components/base";
import AddIcon from "Components/base/Icons/AddIcon";
import TrashIcon from "Components/base/Icons/TrashIcon";
import { ReactComponent as CircleCheckedSuccess }  from "Components/base/Icons/CircleCheckedSuccess.svg";
import { torem } from "styles/theme/toRem";
import style from "./Item.module.scss";
import { toast } from "react-toastify";
import { Input } from "@mui/material";
import { Description } from "@mui/icons-material";

type Item = {
  key: number;
  name: string;
  gameId: string;
  gameType: number;
  description: string;
  requirements: JSON;
  dueDate: number;
  thumbnail: string;
}

type ItemProps = {
  item: Item
}

const defaultRequirement = {
  activetime: 10,
}

const SPItem = ({item, gameInfos, requirements, onPushOut, onDelProgram, open, handleClose}: any) => {
  const [innerItem, setInnerItem] = useState(Object.assign({}, item || {}, {
    requirements: Object.keys(item?.requirements || {}).length ? item?.requirements : defaultRequirement, 
    description: item?.desc ? item?.desc : ''
  }))

  useEffect(() => {
    if (item) {
      setInnerItem(Object.assign({}, item || {}, {requirements: Object.keys(item?.requirements || {}).length ? item?.requirements : defaultRequirement}))
    }
  }, [item]);

  useEffect(() => {
    if (gameInfos?.length) {
      setGi(gameInfos)
    }
  }, [gameInfos]);

  const onChange = (key: any, val: any) => {
    if (key === 'gameId') {
      return onChangeGameId(val)
    }
    const newChanges = {[key]: val, 'description': innerItem.description}
    if (key == 'desc') {
      newChanges['description'] = val
    }
    setInnerItem(Object.assign({}, innerItem, newChanges))
  }
  const onChangeGame = (ev: any) => {
    const id = ev.target.value
    if (id == "-1") {
      ev.preventDefault()
      return
    }
    onChangeGameId(id)
  }
  const onChangeGameId = (id: any) => {
    const game = gameInfos.find((e: any) => e.ID == id)
    if (!game) {
      toast.error("No game found !!!", { autoClose: 3000 });
      const xxx = {gameId: id, gameType: "", name: "", thumbnail: ""}
      setInnerItem(Object.assign({}, innerItem, xxx))
      return 
    }
    const xxx = {gameId: id, gameType: game.GameTypeID, name: game.Name, thumbnail: game.Thumbnail }
    setInnerItem(Object.assign({}, innerItem, xxx))
  }
  const onChangeRequirement = (oldVal: any, newVal: any) => {
    if (Object.hasOwn(innerItem.requirements, newVal)) {
      toast.error("Already has this condition type !!!", { autoClose: 3000 });
      return
    }
    if (!Object.hasOwn(innerItem.requirements, oldVal)) {
      return
    }
    const newReq = Object.assign({}, innerItem.requirements)
    const oldValue = innerItem.requirements[oldVal]
    newReq[newVal] = oldValue
    delete newReq[oldVal] 

    setInnerItem(Object.assign({}, innerItem, {requirements: newReq}))
  }
  const onChangeRequirementValue = (rq: any, newVal: any) => {
    const newReq = Object.assign({}, innerItem.requirements, {[rq]: +newVal})
    setInnerItem(Object.assign({}, innerItem, {requirements: newReq}))
  }
  const addCondition = () => {
    if (Object.keys(innerItem.requirements).length > 2) {
      toast.error("Max condition is 3 !!!", { autoClose: 3000 });
      return
    }
    let items = [...requirements]
    if (Object.keys(innerItem.requirements).length) {
      Object.keys(innerItem.requirements).map(e => {
        items = items.filter((el: any) => el.type != e)
      })
    }
    const el = items[Math.floor(Math.random()*items.length)]
    const newReq = Object.assign({}, innerItem.requirements, {[el.type]: 0})
    setInnerItem(Object.assign({}, innerItem, {requirements: newReq}))
  }
  const onDelReq = (key: any) => {
    if (Object.keys(innerItem.requirements).length < 2) {
      toast.error("Min condition is 1 !!!", { autoClose: 3000 });
      return
    }
    const newReq = Object.assign({}, innerItem.requirements)
    delete newReq[key] 
    setInnerItem(Object.assign({}, innerItem, {requirements: newReq}))
  }
  const onCompleteProgram = () => {
    onPushOut(innerItem)
  }
  const onDeleteProgram = () => {
    onDelProgram(innerItem)
  }
  const [searchText, setSearchText] = useState("")
  const [gi, setGi] = useState(gameInfos)
  let to: any = null;

  const onSearchGame = (text: any) => {
    setSearchText(text)
    if (to) {
      clearTimeout(to)
    }
    if (!text) {
      setGi(gameInfos)
      return
    }
    to = setTimeout(() => {
      const newGi = gameInfos.filter((el: any) => el.Name.toLowerCase().match(text.toLowerCase()))
      setGi(newGi)
    }, 1000)
  }

  return (
    <Dialog
        open={open as boolean}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="profile-workouts"
        style={{width: "1200px !important", maxWidth: "1200px !important"}} 
    >
      <DialogTitle
          id="alert-dialog-title"
          className="alert-dialog-title"
      >
        Edit Program
      </DialogTitle>
      <DialogContent className="chart-and-history-container">
        <div className={style["inputItemSP"]}>
          <Grid
            container
            spacing={torem(16)}
            className={style["m-b-20"]}
          >
            <Grid item xs={3}>
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
                required
                className={style["label-title"]}
              >
                No.
              </InputLabel>
              <div>{innerItem.key}</div>
            </Grid>
            <Grid item xs={9}>
              <InputLabel
                // ref={inputLabel}
                htmlFor="outlined-machine-type"
                required
                className={style["label-title"]}
              >
                Program
              </InputLabel>
              <Select
                name="program"
                value={innerItem.gameId}
                onChange={onChangeGame}
                fullWidth
              >
                <div style={{margin: '10px 20px', width: '1005'}}>
                  <Input fullWidth value={searchText} onChange={(ev: any) => onSearchGame(ev.target.value)}></Input>
                </div>
                {gi.map((e: any) => (<MenuItem key={`select-game-${innerItem.key}-${e.ID}`} value={e.ID}>{`${e.ID} - ${e.Name}`}</MenuItem>))}
                  
              </Select>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={torem(16)}
            className={style["m-b-20"]}
          >
            <Grid item xs={3}>
              <TextField
                required
                id="gameId"
                label="Game Id"
                placeholder="Enter Game Id"
                fullWidth
                name="gameId"
                margin="normal"
                variant="outlined"
                error={!innerItem.gameId}
                onChange={(event:any) => onChange('gameId', event?.target?.value)}
                value={innerItem.gameId}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                required
                id="gameType"
                label="Game Type"
                placeholder="Enter Game Type"
                fullWidth
                name="gameType"
                margin="normal"
                variant="outlined"
                type="number"
                error={!innerItem.gameType}
                onChange={(event:any) => onChange('gameType', +event?.target?.value)}
                value={innerItem.gameType}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="name"
                label="Game Name"
                placeholder="Enter Game Name"
                fullWidth
                name="gameName"
                margin="normal"
                variant="outlined"
                error={!innerItem.name}
                onChange={(event:any) => onChange('name', event?.target?.value)}
                value={innerItem.name}
              />
            </Grid>
          </Grid>
          {Object.keys(innerItem.requirements)?.map((req: any, idx: number) => {
            return (
            <Grid
              container
              spacing={torem(16)}
            >
              <Grid item xs={5}>
                <FormControl
                  fullWidth
                  className="form-aviron"
                  style={{ marginTop: torem(16) }}
                >
                  <InputLabel
                    // ref={inputLabel}
                    htmlFor="outlined-machine-type"
                    required
                    className={style["label-title"]}
                  >
                    Requirement {(idx + 1)}
                  </InputLabel>
                  <Select
                    name={'select-requirement' + (idx + 1)}
                    value={req}
                    onChange={(ev: any) => onChangeRequirement(req, ev.target.value)}
                    fullWidth
                  >
                    {requirements.map((e: any) => (<MenuItem value={e.type}>{e.name}</MenuItem>))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  required={!idx}
                  id={'requirement' + (idx + 1)}
                  placeholder="Enter Number"
                  fullWidth
                  name={'requirement' + (idx + 1)}
                  margin="normal"
                  variant="outlined"
                  type="number"
                  error={!innerItem.requirements[req]}
                  onChange={(event:any) => onChangeRequirementValue(req, event?.target?.value)}
                  value={innerItem.requirements[req]}
                />
              </Grid>
              <Grid item xs={2}>
                <div className={style["del-area"]} onClick={() => onDelReq(req)}>
                  <TrashIcon></TrashIcon> <span>Delete</span>
                </div>
              </Grid>
            </Grid>)
          })}
          <div className={ style["addGroupConditionButton"] }>
            <div className={style["addButtonText"]} onClick={addCondition}>
              <AddIcon />
              Add Requirement
            </div>  
          </div>
          <Grid
            container
            spacing={torem(16)}
            className={style["m-b-20"]}
          >
            <Grid item xs={12}>
              <TextField
                multiline
                id="desc"
                label="Description"
                placeholder="Enter Description"
                fullWidth
                name="desc"
                margin="normal"
                variant="outlined"
                onChange={(event:any) => onChange('desc', event?.target?.value)}
                value={innerItem.desc}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={torem(16)}
          >
            <Grid item xs={6}>
              <TextField
                required
                id="dueDate"
                label="Due Date"
                placeholder="Enter DueDate"
                fullWidth
                name="dueDate"
                margin="normal"
                variant="outlined"
                type="number"
                error={!innerItem.dueDate}
                onChange={(event:any) => onChange('dueDate', +event?.target?.value)}
                value={innerItem.dueDate}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                id="thumbnail"
                label="Thumbnail"
                placeholder="Enter Thumbnail"
                fullWidth
                name="thumbnail"
                margin="normal"
                variant="outlined"
                error={!innerItem.thumbnail}
                onChange={(event:any) => onChange('thumbnail', event?.target?.value)}
                value={innerItem.thumbnail}
              />
              {!innerItem.thumbnail ? (<div style={{ marginBottom: '10px' }}>
                <FormHelperText>Image use path from GCP Bucket /game-thumbnail/(alpha|prod).</FormHelperText>
              </div>) : null}
              
            </Grid>
          </Grid>
          <div className={style["foot-area"]}>
            <div className={style["success-area"]} onClick={onCompleteProgram}>
              <CircleCheckedSuccess></CircleCheckedSuccess> Mark it complete
            </div>
            <div className={style["success-area"]} onClick={onDeleteProgram}>
              <TrashIcon></TrashIcon> Delete 
            </div>
          </div>
        </div>
        </DialogContent>
        </Dialog>
  )
}

export default SPItem;