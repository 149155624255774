import React, { useEffect, useMemo, useState } from "react";
import style from "./DragableModal.module.scss";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SelectAutoComplete,
} from "Components/base";
import {
  useAdminCreateBetaUserMutation,
  useGetUserNotJoinBetaQuery,
} from "services";
import debounce from "lodash/debounce";
import { handleRenderNotification } from "utils/handleRenderNoti";
import { ButtonWithLoading } from "Components/ButtonWithLoading/ButtonWithLoading";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {arrayMoveImmutable} from 'array-move';


interface DragableModalProps {
  open: boolean;
  handleClose: VoidFunction;
  data: any
  setData: any;
}

interface sortEndProps {
  oldIndex: any;
  newIndex: any;
}

interface sortListProps {
  items: any;
}

interface itemProps {
    value: any;
    label: any;
    key: any;
}

const SortableItem = SortableElement((el: any) => <li>{el.value}</li>);

const SortableList = SortableContainer((props: sortListProps) => {
  const {items} = props
  return (
    <ul>
      {(items || []).map((el: itemProps, idx: number) => {
        const {label, key } = el
        return (
          <SortableItem key={`item-${key}`} index={idx} value={label} />
        )
      })}
    </ul>
  );
});

const DragableModal = (props: DragableModalProps) => {
  const { open, handleClose, data, setData } = props;
  const onSortEnd = (seProps: sortEndProps) => {
    const {oldIndex, newIndex} = seProps
    const newItems = arrayMoveImmutable(data, oldIndex, newIndex)
    setData(newItems)
  };
  return (
    <div className={style["EditGameSettingModal"]}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        data-cy="add-machine-dialog"
        className={style["EditGameSettingModal"]}
      >
        <DialogTitle id="alert-dialog-title">Drag to rearrange container</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="col-sm-12 col-md-5">
              <SortableList items={data} onSortEnd={onSortEnd} />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions id="alert-dialog-actions">
          <Button
            variant="contained"
            onClick={handleClose}
            color="primary"
            className="btn-default"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DragableModal;
